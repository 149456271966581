import { AttributeValueBase, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import MsDyn365, { IActionContext, ICoreContext, IGeneric, IAny } from '@msdyn365-commerce/core';
import Moment from 'moment';
import { getProductUrlSync, convertProductDimensionTypeToDimensionTypes } from '@msdyn365-commerce-modules/retail-actions';
import { isNumber } from 'lodash';
import { toast } from 'react-toastify';

export const _addAttribute = (AttributeName: string, attributeValue: string | undefined) => {
    const attributeObj = {
        // @ts-ignore
        '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue',
        Name: AttributeName,
        TextValue: attributeValue,
        TextValueTranslations: []
    };
    attributeObj.Name = AttributeName;
    return attributeObj;
};

export const getQueryParameterByName = (name: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
};

// Remove Duplicate attributes
export const mergeAttributes = (
    prevAttributes: AttributeValueBase[],
    newAttributes: AttributeValueBase[],
    prop: string
): AttributeValueBase[] => {
    const reduced = prevAttributes.filter(aitem => !newAttributes.find(bitem => aitem[prop].toLowerCase() === bitem[prop].toLowerCase()));
    return reduced.concat(newAttributes);
};
// Find  attribute id exist update it other wise push
export const findAttribute = async (
    currentAttributes: AttributeValueBase[],
    attributeObj: AttributeValueBase
): Promise<AttributeValueBase[]> => {
    const attrIndex = currentAttributes.findIndex(
        (attr: AttributeValueBase) => attr.Name?.toLowerCase() === attributeObj.Name?.toLowerCase()
    );
    if (attrIndex > -1) {
        currentAttributes[attrIndex] = attributeObj;
    } else {
        currentAttributes.push(attributeObj);
    }
    return currentAttributes;
};

// Set Order and Cart Attribues
export const setNameAttribute = (attrName: string, value: string): AttributeValueBase => {
    const privIdAttribute = {
        // @ts-ignore
        '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue',
        Name: attrName,
        ExtensionProperties: [],
        TextValue: value,
        TextValueTranslations: []
    };
    return privIdAttribute as AttributeValueBase;
};

export const checkImageExists = (path: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve('ok');
        img.onerror = () => reject('error');
        img.src = path;
    });
};

export function formatPrice(amount: string | undefined, currency: string | undefined, locale: string | undefined): string {
    if (!amount || !currency) {
        return amount || '';
    }
    const priceAmount = (amount && Number(amount)) || 0;
    let result: string;

    try {
        result = new Intl.NumberFormat(locale, {
            style: 'currency',
            currencyDisplay: 'symbol',
            currency: currency,
            minimumFractionDigits: 0
        }).format(priceAmount);
    } catch (e) {
        result = `${priceAmount} ${currency}`;
    }

    return result;
}

export const _writeToStorage = (key: string, value: string | undefined): void => {
    if (!key || !value) {
        return;
    }
    if (MsDyn365.isBrowser) {
        const storage = window.localStorage;
        storage.setItem(key, value);
    }
};
export const _readFromStorage = (key: string): string | undefined => {
    if (!key) {
        return undefined;
    }
    if (MsDyn365.isBrowser) {
        const storage = window.localStorage;
        const cookieValue = storage.getItem(key);
        if (cookieValue) {
            return cookieValue;
        }
    }

    return undefined;
};

export const _removeFromStorage = (key: string): void => {
    if (!key) {
        return;
    }
    if (MsDyn365.isBrowser) {
        const storage = window.localStorage;
        storage.removeItem(key);
    }
};
export const _writeToSessionStorage = (key: string, context: ICoreContext<IGeneric<IAny>>, value?: string): void => {
    if (!key || !context || !value) {
        return;
    }
    if (MsDyn365.isBrowser) {
        const storage = context.request.sessionStorage;
        _isValidJson(value) ? storage.setDataInStorage(key, JSON.parse(value)) : undefined;
    }
};
export const _readFromSessionStorage = (key: string, context: ICoreContext<IGeneric<IAny>>): string | undefined => {
    if (!key || !context) {
        return undefined;
    }
    if (MsDyn365.isBrowser) {
        const storage = context.request.sessionStorage;
        const sessionValue = storage.getDataFromStorage(key);
        if (isNumber(sessionValue) || Object.entries(sessionValue).length !== 0) {
            _removeFromSessionStorage(key, context);
            return JSON.stringify(sessionValue);
        }
    }
    return undefined;
};

export const _removeFromSessionStorage = (key: string, context: ICoreContext<IGeneric<IAny>>): void => {
    if (!key || !context) {
        return;
    }
    if (MsDyn365.isBrowser) {
        const storage = context.request.sessionStorage;
        storage.removeDataFromStorage(key);
    }
};

// VSI Customization for Defult selction on PDP
export const _generateProductUrl = (product: SimpleProduct, actionContext: IActionContext): string => {
    if (product.Dimensions && MsDyn365.isBrowser) {
        const productUrl = getProductUrlSync(product, actionContext, undefined);
        const url = new URL(productUrl, window.location.href);

        for (const dimension of product.Dimensions) {
            url.searchParams.set(
                convertProductDimensionTypeToDimensionTypes(dimension.DimensionTypeValue),
                dimension.DimensionValue?.Value ?? ''
            );
        }
        return url.href;
    }
    return '#';
};

// VSI Customization for Date Time Conversion
export const _dateTimeFormat = (date: string): string => {
    if (!date.length) {
        return '';
    }
    const reqFormat = 'YYYY-MM-DD HH:mm:ss';
    var dateObj = new Date(date);
    return Moment(dateObj).format(reqFormat);
};

// Will be used to check  image existance in media libray
export async function hasImage(swatchImageUrl?: string): Promise<boolean> {
    if (!swatchImageUrl) {
        return false;
    }
    return new Promise<boolean>(resolve => {
        try {
            const http = new XMLHttpRequest();
            http.open('HEAD', swatchImageUrl, true);

            http.addEventListener('load', () => {
                resolve(http.status === 200 || http.status === 201);
            });

            http.addEventListener('error', () => {
                resolve(false);
            });

            http.send();
        } catch {
            resolve(false);
        }
    });
}

/* VSI Customization - To validate json */
export const _isValidJson = (json: string): boolean => {
    try {
        JSON.parse(json);
    } catch (e) {
        return false;
    }
    return true;
};

/* VSI Customization - To to get discount in percentage */
export const _getDiscountPercentage = (price?: number, basePrice?: number): string | null => {
    if (!price || !basePrice) {
        return null;
    }
    return (100 - (price / basePrice) * 100).toFixed(0);
};

/* VSI Customization - To to trigger toast notifications */
export const _triggerToast = (message?: string): void => {
    if (!message) {
        return;
    }
    toast.dark(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
    });
};
